<template>
  <div
    class="border border-slate-25 dark:border-slate-800/60 bg-white dark:bg-slate-900 h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-shrink-0 flex-grow-0"
  >
    <span v-if="showProviders">
      <page-header
        :header-title="$t('INBOX_MGMT.ADD.WHATSAPP.TITLE')"
        :header-content="$t('INBOX_MGMT.ADD.WHATSAPP.DESC')"
      />
      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label>
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.LABEL') }}
          <select v-model="provider">
            <option value="whatsapp_cloud">
              {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.WHATSAPP_CLOUD') }}
            </option>
            <option value="twilio">
              {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.TWILIO') }}
            </option>
            <option value="whatsapp_unofficial">
              {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.WHATSAPP_UNOFFICIAL') }}
            </option>
          </select>
        </label>
      </div>
    </span>

    <twilio v-if="provider === 'twilio'" type="whatsapp" />
    <three-sixty-dialog-whatsapp v-else-if="provider === '360dialog'" />
    <whatsapp-unofficial v-else-if="provider === 'whatsapp_unofficial'" />
    <cloud-whatsapp v-else />
  </div>
</template>

<script>
import PageHeader from '../../SettingsSubPageHeader.vue';
import Twilio from './Twilio.vue';
import ThreeSixtyDialogWhatsapp from './360DialogWhatsapp.vue';
import CloudWhatsapp from './CloudWhatsapp.vue';
import WhatsappUnofficial from './WhatsappUnofficial.vue';

export default {
  components: {
    PageHeader,
    Twilio,
    ThreeSixtyDialogWhatsapp,
    CloudWhatsapp,
    WhatsappUnofficial,
  },
  data() {
    return {
      provider: 'whatsapp_cloud',
      showProviders: true,
    };
  },
  mounted() {
    this.$root.$on('hide-providers', () => {
      this.showProviders = false;
    });
  },
};
</script>
