<template>
  <div>
    <div
      v-if="qrCodeImage"
      class="border border-slate-25 dark:border-slate-800/60 bg-white dark:bg-slate-900 h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-shrink-0 flex-grow-0"
    >
      <form class="mx-0 flex flex-wrap">
        <div class="w-full">
          <page-header
            :header-title="$t('INBOX_MGMT.ADD.QRCODE.TITLE')"
            :header-content="$t('INBOX_MGMT.ADD.QRCODE.DESC')"
          />
        </div>
        <div>
          1. {{ $t('INBOX_MGMT.ADD.QRCODE.STEP_BY_STEP.ONE') }} <br />
          2. {{ $t('INBOX_MGMT.ADD.QRCODE.STEP_BY_STEP.TWO') }} <br />
          3. {{ $t('INBOX_MGMT.ADD.QRCODE.STEP_BY_STEP.THREE') }} <br />
          4. {{ $t('INBOX_MGMT.ADD.QRCODE.STEP_BY_STEP.FOUR') }} <br />
        </div>
      </form>

      <div class="mt-4">
        <img v-show="qrCodeImage" :src="qrCodeImage" alt="qrcode" />
      </div>

      <div class="mt-4">
        <woot-button @click="nextStepAddAgents">
          {{ $t('INBOX_MGMT.ADD.QRCODE.NEXT') }}
        </woot-button>
      </div>
    </div>

    <form v-else class="mx-0 flex flex-wrap" @submit.prevent="createChannel()">
      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: $v.inboxName.$error }">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.LABEL') }}
          <input
            v-model.trim="inboxName"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.PLACEHOLDER')"
            @blur="$v.inboxName.$touch"
          />
          <span v-if="$v.inboxName.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.ERROR') }}
          </span>
        </label>
      </div>

      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: $v.phoneNumber.$error }">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.LABEL') }}
          <input
            v-model.trim="phoneNumber"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.PLACEHOLDER')
            "
            @blur="$v.phoneNumber.$touch"
          />
          <span v-if="$v.phoneNumber.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.ERROR') }}
          </span>
        </label>
      </div>

      <div class="w-full flex items-center gap-2">
        <input
          v-model="rejectCall"
          type="checkbox"
          @change="onUpdateRejectCall($event)"
        />

        <label for="reject_call">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.REJECT_CALL.LABEL') }}
        </label>
      </div>

      <div
        v-if="rejectCall"
        class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]"
      >
        <label :class="{ error: $v.rejectCallDescription.$error }">
          <textarea
            v-model="rejectCallDescription"
            rows="4"
            :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.REJECT_CALL.PLACEHOLDER')"
            @blur="$v.rejectCallDescription.$touch"
          />
          <span v-if="$v.rejectCallDescription.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.REJECT_CALL.ERROR') }}
          </span>
        </label>
      </div>

      <div class="w-full flex items-center gap-2">
        <input
          v-model="groupsIgnore"
          type="checkbox"
          @change="onUpdateGroupsIgnore($event)"
        />

        <label for="reject_call">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.GROUPS_IGNORE') }}
        </label>
      </div>

      <div class="w-full flex items-center gap-2">
        <input
          v-model="syncFullHistory"
          type="checkbox"
          @change="onUpdateSyncFullHistory($event)"
        />

        <label for="reject_call">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.SYNC_FULL_HISTORY') }}
        </label>
      </div>

      <div class="w-full flex items-center gap-2">
        <input
          v-model="importContacts"
          type="checkbox"
          @change="onUpdateImportContacts($event)"
        />

        <label for="reject_call">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.IMPORT_CONTACTS') }}
        </label>
      </div>

      <div class="w-full flex items-center gap-2 mb-2">
        <input
          v-model="importMessages"
          type="checkbox"
          @change="onUpdateImportMessages($event)"
        />

        <label for="reject_call">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.IMPORT_MESSAGES') }}
        </label>
      </div>

      <div
        v-if="importMessages"
        class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]"
      >
        <label>
          <span>
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.DAYS_LIMIT_IMPORT_MESSAGES.LABEL') }}
          </span>
          <input
            v-model.trim="daysLimitImportMessages"
            type="number"
            :style="{ marginBottom: 0 }"
            :placeholder="
              $t(
                'INBOX_MGMT.ADD.WHATSAPP.DAYS_LIMIT_IMPORT_MESSAGES.PLACEHOLDER'
              )
            "
          />
          <span class="text-xs">
            {{
              $t('INBOX_MGMT.ADD.WHATSAPP.DAYS_LIMIT_IMPORT_MESSAGES.OPTIONAL')
            }}
          </span>
        </label>
      </div>

      <div class="w-full mt-4">
        <woot-submit-button
          :loading="loading"
          :disabled="loading"
          :button-text="$t('INBOX_MGMT.ADD.WHATSAPP.SUBMIT_BUTTON')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import PageHeader from '../../SettingsSubPageHeader.vue';
import { isPhone } from 'shared/helpers/Validators';
import router from '../../../../index';
import api from './axios_service.js';

export default {
  components: { PageHeader },
  mixins: [alertMixin],
  data() {
    return {
      inboxName: '',
      phoneNumber: '',
      daysLimitImportMessages: '',
      rejectCallDescription: '',
      syncFullHistory: false,
      rejectCall: false,
      groupsIgnore: false,
      importContacts: false,
      importMessages: false,
      isCreating: false,
      qrCodeImage: '',
      whatsappChannel: {},
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      currentAccountId: 'getCurrentAccountId',
    }),

    loading() {
      return this.isCreating;
    },

    account() {
      return this.currentUser.accounts.find(account => {
        return account.id === this.currentAccountId;
      });
    },

    accountName() {
      return this.account?.name;
    },
  },
  validations: {
    inboxName: { required },
    phoneNumber: { required, isPhone },
    rejectCallDescription: {
      // eslint-disable-next-line no-unused-vars
      isPresent(value) {
        return this.rejectCall ? !!this.rejectCallDescription : true;
      },
    },
  },
  methods: {
    getCheckboxValue(event) {
      return event.target.checked;
    },

    onUpdateRejectCall(event) {
      this.rejectCall = this.getCheckboxValue(event);
    },

    onUpdateGroupsIgnore(event) {
      this.groupsIgnore = this.getCheckboxValue(event);
    },

    onUpdateSyncFullHistory(event) {
      this.syncFullHistory = this.getCheckboxValue(event);
    },

    onUpdateImportContacts(event) {
      this.importContacts = this.getCheckboxValue(event);
    },

    onUpdateImportMessages(event) {
      this.importMessages = this.getCheckboxValue(event);
    },

    nextStepAddAgents() {
      router.replace({
        name: 'settings_inboxes_add_agents',
        params: {
          page: 'new',
          inbox_id: this.whatsappChannel.id,
        },
      });
    },

    async selectQRCodeImage() {
      const response = await api().get(`/whatsapp/qrcode/${this.inboxName}`);
      console.log('response qr code', response)
      return response;
    },

    async listenQRCode() {
      try {
        const response = await this.selectQRCodeImage();
        const { data } = response;

        if (data && data.base64) {
          this.$root.$emit('hide-providers');
          this.isCreating = false;
          this.qrCodeImage = data.base64;
        } else {
          this.listenQRCode();
        }
      } catch (error) {
        this.showAlert(error);
      }
    },

    async createChannel() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.isCreating = false;
        return;
      }

      this.isCreating = true;

      const params = {
        inbox_name: this.inboxName,
        number: this.phoneNumber,
        reject_call: this.rejectCall,
        reject_call_description: this.rejectCallDescription,
        groups_ignore: this.groupsIgnore,
        sync_full_history: this.syncFullHistory,
        import_contacts: this.importContacts,
        user_id: this.currentUser?.id,
        chatwoot_id: this.currentAccountId,
        chatwoot_organization: this.accountName,
        chatwoot_token: this.currentUser?.access_token,
        days_limit_import_messages: this.daysLimitImportMessages || 7,
      };

      try {
        const response = await api().post('/whatsapp/instance', params);
        console.log('response', response)
        this.listenQRCode();

        const whatsappChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          {
            name: this.inboxName,
            channel: {
              type: 'whatsapp',
              phone_number: this.phoneNumber,
              provider: 'whatsapp_unofficial',
            },
          }
        );

        this.whatsappChannel = whatsappChannel;
      } catch (error) {
        this.showAlert(
          error.message || this.$t('INBOX_MGMT.ADD.WHATSAPP.API.ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>
