import axios from 'axios';

export default () =>
  axios.create({
    baseURL: `${window.location.origin}/`,
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
